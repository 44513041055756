import dayjs from 'dayjs';
import { UpdateCustomerStatusRequestCustomerStatus } from '@src/service/apis/generatedv3/customer-controller';
import { IsIntersectionNonEmptyFn } from '@src/types/utils/utils';
import { addThousandsSeparatorOnChange } from '@src/utils/formatNumber';
import { PaymentStatusType } from '@src/types/nanoCardPaymentStatus';

export const applyCase = (string: string, type: 'capitalize'): string => {
  if (type === 'capitalize') {
    return string?.slice(0, 1)?.toUpperCase() + string?.slice(1)?.toLowerCase();
  }
};

export const removeSpace = (string: string): string => {
  return `${string.replace(/\s/g, '')}`;
};

export const replaceUnderScoreToSpaceSentenceCase = (
  string: string,
): string => {
  return string
    ?.split('_')
    ?.map(
      word =>
        `${word?.charAt(0)?.toUpperCase()}${word?.slice(1)?.toLowerCase()}`,
    )
    ?.join(' ');
};

export const snack_uppercase = (string: string): string => {
  return `${string?.toUpperCase().split(' ').join('_')}`;
};

export const getFileExtension = (fileName: string): string =>
  fileName.slice(fileName.lastIndexOf('.'));

export const getUserStatusChangeButtonText = (
  currentUserStatus: string,
): string => {
  if (!currentUserStatus) return;
  if (currentUserStatus === UpdateCustomerStatusRequestCustomerStatus.ACTIVE)
    return 'Lock account';
  if (
    currentUserStatus === UpdateCustomerStatusRequestCustomerStatus.DEACTIVATED
  )
    return 'Reactivate account';

  return 'Unlock account';
};

export const numberFormat = (number: number): string =>
  Intl.NumberFormat(['id']).format(number);

export const randomNum = (numberOfArray = 1): number | Uint8Array => {
  // conditional for avoid error
  if (typeof window !== 'undefined') {
    if (typeof window.crypto === 'undefined') {
      return Math.random();
    } else {
      return window.crypto.getRandomValues(new Uint8Array(numberOfArray));
    }
  }
};

/**
 * Function to check if intersection of array1 and array2 is non-empty
 * @param array1 - array of string values
 * @param array2 - array of string values
 * @returns - boolean
 */
export const isIntersectionNonEmpty: IsIntersectionNonEmptyFn = (
  array1: string[],
  array2: string[],
) => !!array1?.some(item => !!array2?.includes(item));

export const convertDateToMonthYYYY = (timestamp: string) => {
  const date = dayjs(Number(timestamp));
  return dayjs(date).format('MMMM YYYY');
};

export const getFormattedAmountWithRounding = (
  value: number,
  decimalPlaces = 0,
) => {
  if (value === null || value === undefined) return 0;
  return `Rp ${addThousandsSeparatorOnChange(value.toFixed(decimalPlaces))}`;
};

export const showPaidBillValue = (
  paymentStatus: string,
  value: number,
): number | string => {
  if (paymentStatus === PaymentStatusType.PAID) {
    return getFormattedAmountWithRounding(value, 2);
  }
  return 'Rp 0';
};

export const formatSizeImage = (value: number) => {
  const units = ['B', 'KB', 'MB', 'GB'];
  let size = value;
  let unitIndex = 0;

  while (size >= 1000 && unitIndex < units.length - 1) {
    size /= 1000;
    unitIndex++;
  }
  return size?.toFixed(0) + ' ' + units[unitIndex];
};

/**
 * Converts a CamelCase string to  normal strong , for example, transforming 'phoneNumber' into 'Phone Number'
 */
export const camelCaseToNormal = (value: string) => {
  const camelCaseToNormal = (string: string) =>
    string.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());

  return camelCaseToNormal(value);
};

export const reloadPage = () => {
  window.location.reload();
};

/**
 * Converts byte size into KB or MB
 */
export const convertFileSize = (
  fileSizeInByte: number,
  metrics: 'KB' | 'MB',
): number => {
  if (metrics === 'KB') {
    return Math.round(fileSizeInByte / 1024);
  } else if (metrics === 'MB') {
    return parseFloat((fileSizeInByte / (1024 * 1024)).toFixed(1));
  } else {
    return 0;
  }
};

/**
 * string format fileSize from Kilobytes
 */
export const formatFileSize = (sizeInKB: number): string => {
  const KB_IN_MB = 1024;
  const KB_IN_GB = KB_IN_MB * 1024;

  if (sizeInKB >= KB_IN_GB) {
    // Convert to GB and remove trailing zeroes
    const sizeInGB = (sizeInKB / KB_IN_GB).toFixed(2);
    return `${parseFloat(sizeInGB)} GB`;
  } else if (sizeInKB >= KB_IN_MB) {
    // Convert to MB and remove trailing zeroes
    const sizeInMB = (sizeInKB / KB_IN_MB).toFixed(2);
    return `${parseFloat(sizeInMB)} MB`;
  } else {
    // Stay in KB
    return `${sizeInKB} KB`;
  }
};

/**
 * formating string phone number';
   example => input: '6281313310000', output: '+62 - 81313310000';
 * @returns {string}
 */
export const formatPhoneNumber = (value: string): string => {
  const countryCode = '+62';
  const localNumber = value.slice(2);
  return `${countryCode} - ${localNumber}`;
};
