import { NUMBER_FORMATTING_REGEX } from '@src/constants/common';
import { Locales } from '@src/types/fund-management';

/**
 * Formats a number or string by adding a thousands separator based on the specified locale.
 * @param ele - The number or string to format.
 * @param locale - The locale to use for formatting (default: Locales.ID).
 * @returns A string representation of the number with thousands separators.
 */
export const addThousandsSeparatorOnChange = (
  ele: number | string,
  locale = Locales.ID,
): string => {
  // Convert the input to a number and format it using the locale's thousands separator
  return Number(ele).toLocaleString(locale);
};

/**
 * Formats a balance amount for NanoCuan by adding thousands and decimal separators.
 * @param balance - The balance to format, can be a number or string.
 * @param thousandSeparator - The character to use for thousands separation (default: '.').
 * @param decimalSeparator - The character to use for decimal separation (default: ',').
 * @returns A formatted balance string.
 */
export const formatBalanceForNanoCuan = (
  balance: number | string,
  thousandSeparator = '.',
  decimalSeparator = ',',
): string => {
  // Return '0' if the balance is null or undefined
  if (balance === null || balance === undefined) return '0';

  const parsedAmount = parseFloat(String(balance));

  // Split the number into whole and fractional parts
  const [wholePart, fractionalPart] = parsedAmount.toString().split('.');

  // Format the whole part with the thousands separator
  const formattedWholePart = wholePart.replace(
    NUMBER_FORMATTING_REGEX,
    thousandSeparator,
  );

  // Combine the formatted whole part with the fractional part, if it exists
  return fractionalPart
    ? formattedWholePart + decimalSeparator + fractionalPart
    : formattedWholePart;
};

/**
 * Converts a scientific notation number to a fixed-point notation.
 * @param scientificNumber - The number in scientific notation to convert (default: 0).
 * @returns A string representation of the number in fixed-point notation or '-' if the input is 0.
 */
export const scientificToFixedNumber = (scientificNumber = 0): string => {
  // Return '-' for zero input
  if (!scientificNumber) {
    return '-';
  }

  // Handle small numbers in scientific notation
  if (Math.abs(scientificNumber) < 1.0) {
    const exponent = parseInt(scientificNumber.toString().split('e-')[1]);
    if (exponent) return scientificNumber.toFixed(exponent).replace('.', ',');
    return scientificNumber.toString().replace('.', ',');
  }

  // For other numbers, just replace '.' with ','
  return scientificNumber.toString().replace('.', ',');
};
